import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {toast}  from 'react-toastify';
import * as Constant from '../../common/constants';
import { useLocation } from 'react-router-dom';
import * as functions from '../../common/functions';

function LayerList({map }){

    var imageLayer;
    const Mapstate = useSelector((state:any) => state.mapstateReducer);
    const location = useLocation();

    useEffect(()=>  { 
        window.addEventListener('Ndvicalled',async ()=>{
            if(map !== null){
                var coordinate =  (sessionStorage.getItem('feature') === 'AOI') ? (sessionStorage.getItem('coordinate_poly')) : (sessionStorage.getItem('cod'));
                var extend =  (sessionStorage.getItem('feature') === 'AOI') ? (sessionStorage.getItem('aoi_extend')) : (sessionStorage.getItem('field_extend'));
                AddingLayers(coordinate, extend);
            }                 
        });   

        window.addEventListener('Natural Color',async ()=> {
           ClearLayer(); 
        }); 

        window.addEventListener('dateRange',async ()=>{     
            AddingLayers(sessionStorage.getItem('coordinate_poly'), sessionStorage.getItem('extend_'));               
        });
         
        window.addEventListener('clearlayer',()=>{
            ClearLayer();
        });
        return  () =>{
            window.removeEventListener('Ndvicalled',()=>{});
            window.removeEventListener('dateRange',()=>{});
            window.removeEventListener('clearlayer',()=>{});
        };
    },[map]);


    useEffect(()=>{
        if(map ){ // || location.pathname.includes('/filter')
            map.getLayers().getArray().filter(layer => layer instanceof ImageLayer).forEach(layer => map.removeLayer(layer));
        }
            
    },[Mapstate]);
    
    function AddingLayers(getCoordinate, extend) { //adding Layer
       
        if( localStorage.getItem('AccessToken') !== null ){

        const split_string = getCoordinate.split(","), split_string1 = extend.split(",");     
        let resu="", coordinst ="", url="";
        

        for(let i=0;i<split_string.length;i++){
            if(i%2 !== 0){
                resu = resu + coordinst.concat(split_string[i],","); 
            }else{
                resu = resu + coordinst.concat(split_string[i]," ");
            }
        }

        if(sessionStorage.getItem('shapedata') === 'rectangle') {
            url = Constant.baseUrl + '&MAXCC=20' + '&BBOX=' + extend + '&FORMAT=image/jpeg&' +  sessionStorage.getItem('from') + '/' + sessionStorage.getItem('to') + '&' + Constant.evalScript + Constant.quality;
        } else {
            url = Constant.baseUrl + '&SERVICE=WMS&VERSION=1.3.0&MAXCC=40&CRS=EPSG:3857' + '&BBOX=' + extend + '&geometry=POLYGON((' + resu.substring(0, resu.length - 1) + '))&' + Constant.evalScript + Constant.quality + '&TRANSPARENT=FALSE&TIME=' + sessionStorage.getItem('from') + '/' + sessionStorage.getItem('to');
        }

            functions.validateBuffer(url).then((result) => {
            
                if (result) {    
                    toast.warn(Constant.TOAST_NDVI_ERROR,{position : Constant.TOAST_POSITION});
                } else {
                    imageLayer =  new ImageLayer({
                        source: new Static({
                            url:url, imageExtent: [parseInt(split_string1[0]),parseInt(split_string1[1]),parseInt(split_string1[2]),parseInt(split_string1[3])], projection: 'EPSG:32714', 
                        }),
                    });
                    map.addLayer(imageLayer);
                    toast.success(Constant.TOAST_NDVI_SUCCESS,{position : Constant.TOAST_POSITION});
                }
               }); 
             }         
            else
            {
                toast.warn('Login to proceed',{position : Constant.TOAST_POSITION,});
            }
            
    } 
    
    function ClearLayer() {
       
       if(map!==null) {
        map.getLayers().getArray().filter(layer => layer instanceof ImageLayer).forEach(layer => map.removeLayer(layer));
       } 
    }
    return null;
}

export default LayerList;

