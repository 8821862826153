export const PROD_BASE_URL = "https://as-terramate-webapi-prod.azurewebsites.net/api";
export const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJyZWFjdEFwcCIsImlhdCI6MTY5NjkyODIwOX0.v9EUrZHyZZPDdbKf2PpFkKtMr8MrmuxFkupACSE1itc';
export const SENTINEL_TOKEN ='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ3dE9hV1o2aFJJeUowbGlsYXctcWd4NzlUdm1hX3ZKZlNuMW1WNm5HX0tVIn0.eyJleHAiOjE3MDkxODQ1NjYsImlhdCI6MTcwOTE4MDk2NiwianRpIjoiNTRmNmNhNzgtODU5MS00ZWM3LWFhMzEtNDY3YmI3MmUwNGE1IiwiaXNzIjoiaHR0cHM6Ly9zZXJ2aWNlcy5zZW50aW5lbC1odWIuY29tL2F1dGgvcmVhbG1zL21haW4iLCJzdWIiOiI3NTVlNjA0NC1mMzljLTRjZGQtOWEzZC04OGE2ZmY1NzQ3YWIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiIwNmE5MzdhYy1hOGZjLTQyMjYtODUzMi1jYWU5MDUzODJlZmEiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJjbGllbnRJZCI6IjA2YTkzN2FjLWE4ZmMtNDIyNi04NTMyLWNhZTkwNTM4MmVmYSIsImNsaWVudEhvc3QiOiIyMDIuODguMjA5LjExNCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwicHJlZmVycmVkX3VzZXJuYW1lIjoic2VydmljZS1hY2NvdW50LTA2YTkzN2FjLWE4ZmMtNDIyNi04NTMyLWNhZTkwNTM4MmVmYSIsImNsaWVudEFkZHJlc3MiOiIyMDIuODguMjA5LjExNCIsImFjY291bnQiOiJlNTY0YjIzNC1mZGJkLTQxNDctYTI1YS1hZTlkOWM3OTQ4Y2EifQ.fS3QTA-jZPS-VoHPWU-seMabH06xHqBCCIodVz99bpvtwLUUniK-q84Av5xcaFCGrYZw3pU67ovXdvGvWzobjNlQB_YEGSUSjemVGiKHS8YjKaDzHG5Z-_ejj1LWMIils2H3cXWYI-Y3oOOzY_0YnH9v347O2zF14aoi2Bz4wSWv-zd5P3Sjzu9UpJWu1SsEbVDKfJR8RK5Umm_5LSRRqEPfnIuvUPAy-WKloz7UPX7_a8vRaAFLYVDW8qy5873ZnCvqDaG8AJa0tD-0B7W1oATtvsBXs7P6pM9cn_fWfy5RkoiGPP1Ov84cCsZlVw7-Koo7KHyjcMWwsA0pCOqiXg';

//azure  
export const CONTAINER_NAME = `terramate-ai`;
export const VIEW_KEY = `cES8wdjWJA5W+exA529kHQiUyIKLD3TaOBMeBmJKpwui7JmwzIhqtaHdq6R/1kPQhbY7k7PHdSMT+AStBaWxrw==`;
export const PROJECT = 'terramate';
export const MAPURL = 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}';
export const CROPROTATION = "Keep record of a crop rotation history and plan the future sowing operations to maintain soil fertility";

//toast message
export const SOMETHING_TOAST = "Something went wrong";
export const LOGIN_TOAST = "Login successfully";
export const TOAST_POSITION = 'top-center';
export const TOAST_NDVI_ERROR = 'No Data Available';
export const TOAST_NDVI_SUCCESS = 'Layer Applied';
export const TOAST_DATE_RANGE = 'Invalid Date Range';
export const TOAST_INVALID_RESULT = "Upload the Image to see Result";
export const TOAST_INVALID_IMG = 'invalid Image';

//Sentinel Hub
export const baseUrl = 'https://services.sentinel-hub.com/ogc/wms/61ed7b70-26c6-4164-b11b-6ed0f5a93b20?REQUEST=GetMap&LAYERS=NATURAL-COLOR&WIDTH=512&HEIGHT=343';
export const evalScript = 'EVALSCRIPT=bGV0IG5kdmkgPSAoQjA4IC0gQjA0KSAvIChCMDggKyBCMDQpOwpyZXR1cm4gY29sb3JCbGVuZChuZHZpLAogICBbLTAuMiwgMCwgMC4xLCAwLjIsIDAuMywgMC40LCAwLjUsIDAuNiwgMC43LCAwLjgsIDAuOSwgMS4wIF0sCiAgIFtbMCwgMCwgMCxkYXRhTWFza10sCQkJCQkJCSAKICAgIFsxNjUvMjU1LDAsMzgvMjU1LGRhdGFNYXNrXSwgICAgICAgCiAgICBbMjE1LzI1NSw0OC8yNTUsMzkvMjU1LGRhdGFNYXNrXSwgCiAgICBbMjQ0LzI1NSwxMDkvMjU1LDY3LzI1NSxkYXRhTWFza10sICAKICAgIFsyNTMvMjU1LDE3NC8yNTUsOTcvMjU1LGRhdGFNYXNrXSwgCiAgICBbMjU0LzI1NSwyMjQvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICAgIFsyNTUvMjU1LDI1NS8yNTUsMTkxLzI1NSxkYXRhTWFza10sCiAgICBbMjE3LzI1NSwyMzkvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICAgIFsxNjYvMjU1LDIxNy8yNTUsMTA2LzI1NSxkYXRhTWFza10sCiAgICBbMTAyLzI1NSwxODkvMjU1LDk5LzI1NSxkYXRhTWFza10sICAKICAgIFsyNi8yNTUsMTUyLzI1NSw4MC8yNTUsZGF0YU1hc2tdLCAgCiAgICBbMCwxMDQvMjU1LDU1LzI1NSxkYXRhTWFza10gICAgICAgIAogICBdKTs=';
export const quality = '&QUALITY=70';
