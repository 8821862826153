import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cropIdentifyActions } from "../../actions/cropIdentifyActions";
import { AiOutlineClose } from "react-icons/ai";
import { LuFilePlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Constant from '../../common/constants';

function Cropgrowth({ }) {

  const [isInput, setInputstate] = useState(true);
  const [isImageVisible, setImagevisible] = useState(false)
  const [imagesrc, setImagesrc] = useState('');
  const [isScan, setScan] = useState(false);
  const [imageSource, setImagesource] = useState();
  const [resultobj, setResultObj] = useState({ crop_name: '', accuracy: '', crop_growth_stage: '' });
  const [isImageView, setImageView] = useState(false);
  const [isDisablebtn, setDisablebtn] = useState(true);

  const dispatch = useDispatch();
  const growth = useSelector((state: any) => state.growthReducer); //predict
  const growthtable = useSelector((state: any) => state.growthallReducer); //getAll
  const navigate = useNavigate();

  const OnchangeImageviewer = (event: any) => {
    growth.isSuccess = false;
    const fileObject = event.target.files[0];
    setImagesource(fileObject);
    const urlobject = URL.createObjectURL(fileObject);
    setImagesrc(urlobject);
    setInputstate(!isInput);
    setImagevisible(!isImageVisible);
  }

  useEffect(() => {
    growth.isSuccess = false;
    dispatch(cropIdentifyActions.growthTableAll());
  }, []);

  useEffect(() => {

    setDisablebtn(false);
    if (growth.isSuccess) {
      setScan(!isScan);
      setImageView(!isImageView);
      setInputstate(!isInput);
      setImagevisible(!isImageVisible);
      setResultObj(growth.diseasedata.resultObject);
    }

  }, [growth.isSuccess]);

  useEffect(() => {
    if (growth.isSuccess) {
      dispatch(cropIdentifyActions.growthTableAll());
    }
  }, [growth.isSuccess]);

  useEffect(() => {
    if (growth.isError) {
      toast.warn(growth.tabledata.errorMessage, { position: Constant.TOAST_POSITION });
      setScan(!isScan); setImagesrc('');
      setInputstate(!isInput); setImagevisible(!isImageVisible);
      setDisablebtn(!isDisablebtn);
    }
    growth.isError = false
  }, [growth.isError]);

  const onClickSubmit = () => {
    const formdata = new FormData();
    if (imagesrc && !isInput) {
      setScan(!isScan);
      formdata.append('image', imageSource);
      formdata.append('predictType', "growth");
      dispatch(cropIdentifyActions.growthIdentifyAll(formdata));
      setDisablebtn(!isDisablebtn);
    }
    else {
      toast.warn(Constant.TOAST_INVALID_RESULT, { position: Constant.TOAST_POSITION });
    }
  }

  const onChangeImage = () =>{
    if(imagesrc){
     setImagesrc('');
     setImagevisible(!isImageVisible);
     setInputstate(!isInput); 
   }
 }

  return (
    <div className="out-contain">
      <div className="crop-identify-container">
        <div >
          <div  className="cropIdentify-label-container" >
            <label style={{ fontSize: '15px' }}>Growth Identification</label>
            <AiOutlineClose onClick={() => navigate('/app')} style={{ cursor: 'pointer', color: 'white', }} />
          </div>
          <div className="image-container-crop-identify">
            <div className="image-classify-container">
              <div className="card-imageview-submit">
                <div className="image-view-container">
                  <div className="image-view-addimage">
                    {isInput && <LuFilePlus style={{ color: 'black', }} size={'2.7em'} />}
                    {isInput && <input type="file" onChange={OnchangeImageviewer} accept="image/jpeg, image/png" />}
                    {isImageVisible && <img id="image-source" src={imagesrc} onClick={onChangeImage} />}
                    {isScan && <div className="scanner"></div>}
                  </div>
                  <div className="button-container-submit">
                    <button className='submit-button' onClick={onClickSubmit} disabled={isDisablebtn} >SUBMIT</button>
                  </div>
                </div>
              </div>
              <div className="card-imageview-output">
                <div className="image-view-container">
                  <div className="image-view-ouput-addimage">
                    {growth.isSuccess ? <div className="result-image-viewer">
                      <div className="image-container">
                        <img src={imagesrc} id="image-source" />
                      </div>
                      <div className="image-container-label">
                        <div className="image-label-data">
                          <div className="inner-container">
                            <label id="label-parameter">Crop Name : {resultobj.crop_name}</label>
                          </div>
                          <div className="inner-container">
                            <label id="label-parameter">Accuracy  : {parseInt(resultobj.accuracy) + "%"}</label>
                          </div>
                          <div className="inner-container">
                            <label id="label-parameter">Growth Stage   : {resultobj.crop_growth_stage}</label>
                          </div>
                        </div>
                      </div>
                    </div> :
                       <div className='cropIdentify-toast-container'>
                          <label>To check status of the plant, click <span ><LuFilePlus size={'1.5em'} /></span> to upload and press submit 
                          </label>
                     </div>}
                  </div>
                </div>

              </div>
            </div>
            <div className="image-classify-container1" >
              <div className="image-classify-tablecontainer" >
                <table id="cropdata" >
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Crop Name</th>
                      <th>Growth Stage</th>
                      <th>Accuracy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {growthtable && growthtable.isSuccess && growthtable.tabledata.map((item: any, index: any) => {

                      return (
                        <tr key={index}>
                          <td id='image-preview-td'>
                            <img src={item.image_url} id='img-table-preview' />
                          </td>
                          <td>{item.crop_name}</td>
                          <td>{item.crop_growth_stage}</td>
                          <td>{parseInt(item.accuracy) + "%"}</td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )

}

export default Cropgrowth;
