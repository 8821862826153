import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import axios from 'axios';
import { fromLonLat } from 'ol/proj';
import { Style, Icon } from 'ol/style';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Assets from '../../assets/search.png';
import { AiOutlineClose } from 'react-icons/ai';

const SearchBar = ({ map }) => {
  const [query, setQuery] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isClose, setClosestate] = useState(false);

  const iconStyle = new Style({
    image: new Icon({
      src: Assets,
      anchor: [0.5, 1], 
    }),
  });

  const handleChange = (e) => {
    const userInput = e.target.value;
    setQuery(userInput);

    if(userInput.length === 0){
      setClosestate(false); 
    }
    
    if (userInput.length > 3) {
      axios
        .get(`https://photon.komoot.io/api/?q=${userInput}&limit=8&lang=en`)
        .then((res) => {
          const newSuggestions = res.data.features.map((feature) => ({
            place: feature.properties.name,
            coordinates: feature.geometry.coordinates,
          }));
          setSuggestions(newSuggestions);
          setShowSuggestions(true);
          setHighlightedIndex(-1);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setShowSuggestions(false);
    }
  };

  const handleClick = (selectedPlace) => {
    setQuery(selectedPlace);
    setClosestate(true);
    const selectedSuggestion = suggestions.find((suggestion) => suggestion.place === selectedPlace);

    if (selectedSuggestion) {
      const { coordinates } = selectedSuggestion;
      setCoordinates((prevCoordinates) => [
        ...prevCoordinates,
        { place: selectedSuggestion.place, coordinates },
      ]);

      const iconFeature = new Feature({
        geometry: new Point(fromLonLat(coordinates)), 
      });

      iconFeature.setStyle(iconStyle);

      if (map) {
        const vectorLayer = new VectorLayer({
          source: new VectorSource({
            features: [iconFeature], 
          }),
        });
        map.getLayers().forEach(layer => {
          if (layer instanceof VectorLayer) {
            layer.getSource().clear(); 
          }
        });
        map.addLayer(vectorLayer);
        map.getView().setCenter(fromLonLat(coordinates));
        map.getView().setZoom(17);
      }
    }

    setShowSuggestions(false);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 100);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 40) {
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );
    } else if (event.key === 'Enter' && highlightedIndex >= 0) {
      handleClick(suggestions[highlightedIndex].place);
    }
  };

  const onChangeCloseclick = () =>{
    setQuery('');setClosestate(!isClose);
    map.getLayers().forEach(layer => {
          if (layer instanceof VectorLayer) {
            layer.getSource().clear(); 
          }
    });
  }

  return (
    <div className="search-bar">
      <div style={{ display: 'flex', backgroundColor: 'white', borderRadius: '5px' }}>
        <input
          type="text"
          value={query}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Search location"
          onFocus={() => setShowSuggestions(true)}
          onKeyDown={handleKeyDown}
        />
        { isClose && <AiOutlineClose onClick={onChangeCloseclick} style={{ padding: '10px 0px 0px 0px',  cursor:'pointer' }}/> }
        <FaSearch style={{ padding: '10px' ,}} />
        
      </div>
      {showSuggestions && query && (
        <ul className="suggestions">
          {suggestions.length ? (
            suggestions.map((suggestion, index) => (
              <li
                key={index}
                className={highlightedIndex === index ? 'highlighted' : ''}
                onClick={() => handleClick(suggestion.place)}
              >
                {suggestion.place}
              </li>
            ))
          ) : (
            <li>No suggestions available</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;