import { useDispatch, useSelector } from "react-redux";
import '../../Component/sidePanel.css';
import { useEffect, useState } from "react";
import { fieldActions } from "../../actions/fieldActions";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { transform } from "ol/proj";
import { mapActions } from "../../actions/mapActions";
import { AiOutlineClose } from "react-icons/ai";
import { FaDrawPolygon } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { Menu, MenuButton, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import * as Constant from '../../common/constants';
import { LuAlertOctagon } from "react-icons/lu";
import { toast } from "react-toastify";
import VectorLayer from "ol/layer/Vector";

export function FieldList({ }) {

  const fieldData = useSelector((state: any) => state.fieldnameReducer);

  const [statePopup, setStatePopup] = useState(true);
  const [isDeletepopUp, setDeletePopup] = useState(false);
  const [isDrawPopup, setDrawPop] = useState(false);
  const [deleteFieldid, setFieldId] = useState('');
  const dispatch = useDispatch();
  const fieldreducer = useSelector((state :any) => state.fieldReducer)
  const FieldidDelete = useSelector((state: any) => state.fieldDeleteReducer);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fieldActions.Fieldname(localStorage.getItem('client_id')));
  }, []);

  useEffect(()=>{ 
    if(fieldreducer.isSuccess){
        toast.success(fieldreducer.fieldData.message, { position: Constant.TOAST_POSITION });     
       }
       fieldreducer.isSuccess = false
   },[fieldreducer.isSuccess]);

   useEffect(()=>{
     if(FieldidDelete.isSuccess){
       dispatch(mapActions.Mapstate(true));
       toast.success(FieldidDelete.fieldData.message, { position: Constant.TOAST_POSITION });
       dispatch(fieldActions.Fieldname(localStorage.getItem('client_id')));
      }
      FieldidDelete.isSuccess = false
  },[FieldidDelete.isSuccess]);

  useEffect(()=>{
      
    if(FieldidDelete.isError){
        toast.warn(FieldidDelete.fieldData.errorMessage, { position: Constant.TOAST_POSITION });     
       }
       FieldidDelete.isError = false
   },[FieldidDelete.isError]);


  const FormatedArray = (data) => {

    const transformedCoordinates = [];
    for (let i = 0; i < data.length; i += 2) {
      const lonLat = transform([data[i], data[i + 1]], 'EPSG:3857', 'EPSG:4326');
      transformedCoordinates.push(lonLat);
    }
    const formattedArray = [].concat(...transformedCoordinates).map((value, index) => {
      if (index % 2 === 1 && index !== data.length - 2) {
        return value + ' ';
      } else {
        return value + ',';
      }
    });
    return formattedArray.join('');
  }


  const Onclickpolygonload = (item) => {

    sessionStorage.setItem('cod', item.field_coordinates);
    sessionStorage.setItem('zoom', item.field_zoom);
    sessionStorage.setItem('field_extend', item.field_extend);
    sessionStorage.setItem('feature', 'myfarm');

    navigate(`/app/field/${item.field_name}`,
      {
        state:
        {
          name: item.field_name,
          coordinate: (item.field_coordinates).toString(),
          fieldId: item._id,
          groupname: (item.group_id == null) ? null : item.group_id.group_name
        }
      });
  }

  const OnchangeAddField = () => {
    dispatch(mapActions.Mapstate(true));
    setDrawPop(!isDrawPopup);
  }

  const OnclickdeleteField = (item: any) => {
    setFieldId(item._id);
    setDeletePopup(!isDeletepopUp);
  }

  return (
    <>
      <div className="side-panel" >
        <AiOutlineClose style={{ display: statePopup ? '' : 'none' }} size={'1.0em'} className='close-icon' onClick={() => navigate('/app')} />
        <label style={{ marginLeft: '10px', fontWeight: 'bold', display: statePopup ? '' : 'none' }}>Field View</label>
        <HiQuestionMarkCircle size={'1.2em'} style={{ marginLeft: '10px', display: statePopup ? '' : 'none' }} />
        {<div className="sidepanel-field-container" style={{ height: '80%', padding: '5px' }}>

          {(Array.isArray(fieldData.fieldname) && fieldData.fieldname.length > 0) ? (fieldData.fieldname && fieldData.fieldname.map((item: any, i: any) => (
            <>
              {
                <div className='field-listView-container-overview' key={item}  >
                  <div className='field-list-polygon'>
                    <div className="polygon-div-container" onClick={() => Onclickpolygonload(item)}>
                      <svg className="svg-polygon" xmlns="http://www.w3.org/2000/svg">
                        <polygon points={FormatedArray(item.field_coordinates)} fill='none' stroke='black' className="custom-polygon" strokeOpacity={0.4} >
                        </polygon>
                      </svg>
                    </div>
                    <div className='field-parameter-column' >
                      <div className='label-container'>
                        <label className='label-fieldname' key={item} style={{ fontWeight: '400', fontSize: '14px' }}>{item.field_name}</label>
                        <Menu menuButton={<MenuButton><PiDotsThreeVerticalBold size={'1.3em'} /></MenuButton>}>
                          <MenuItem onClick={(e) => OnclickdeleteField(item)}>Delete Field</MenuItem>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          ))
          )
            : <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <label>No field created ? To create a new Field, click on the <span style={{ color: '#7e7979', fontWeight: 'bold', cursor: 'pointer' }} >Add Field</span> </label>
            </div>
          }

        </div>}

        {(statePopup) && <div style={{ width: 'inherit', height: 'auto', position: 'fixed', bottom: '0' }} >
          <button className="btn-Add-field" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', borderRadius: '10px' }} onClick={OnchangeAddField} >
            <IoMdAdd style={{ marginRight: '6px', fontWeight: 'bolder' }} size={'1.3em'} />
            Add Field
          </button>
        </div>}
        {isDrawPopup && <DrawPopup close={() => setDrawPop(!isDrawPopup)} />}
        {isDeletepopUp && <Deletepopup close={() => setDeletePopup(!isDeletepopUp)} fieldId={deleteFieldid} />}
      </div>


    </>

  )

}

export function Deletepopup({ close, fieldId }) {

  const dispatch = useDispatch();

  const onChangeDeleteField = () => {
    dispatch(fieldActions.FieldDelete(fieldId));
  }

  return (
    <>
      {<div className="login-form">
        <div className="container-draw-popup-aoi">
          <AiOutlineClose size={'1.3em'} className='close-icon' onClick={close} />

          <div className="drawpopContainer">
            <div className="" style={{ width: '100%', textAlign: 'center', marginTop: '30px', borderRadius: '10px', color: 'white' }}>
              <LuAlertOctagon style={{ marginTop: '20px' }} size={'2.7em'} color="#f5db12" />
              <div style={{ padding: '10px' }}>
                <label>Delete field?</label>
              </div>
              <div style={{ color: "#6e7b7b" }}>
                <p>Deleting will permanently remove the field and all information about it from your account.Try editing the field instead of deleting it</p>
              </div>
              <div style={{ padding: '10px', display: 'flex', }}>
                <button onClick={() => { close() }} className="btn-save" style={{ backgroundColor: 'rgb(130 137 145)', cursor: 'pointer', marginRight: "10px" }} >EDIT FIELD</button>
                <button className="btn-save" onClick={() => { onChangeDeleteField(); close(); }} style={{ backgroundColor: '#4c99e7', cursor: 'pointer' }} >DELETE FIELD</button>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  )
}

export function DrawPopup({ close }) {

  const navigate = useNavigate();
  const fieldreducer = useSelector((state: any) => state.fieldReducer);

  const OnchangeDrawField = () => {
    navigate('/app/myFarm/draw', { state: { isState: "valid" } });
    fieldreducer.isSuccess = false ;
  }

  return (
    <>
      {<div className="login-form">
        <div className="container-draw-popup">
          <AiOutlineClose size={'1.3em'} className='close-icon' onClick={close} />

          <h2 style={{ color: 'white', width: '100%', textAlign: 'center' }}>Select the Field</h2>
          <hr className="draw-popup" />
          <div className="drawpopContainer">
            <div className="" style={{ width: '100%', textAlign: 'center', marginTop: '20px', borderRadius: '10px', color: 'white' }}>
              <FaDrawPolygon style={{ marginTop: '20px' }} size={'2.3em'} />
              <div style={{ padding: '10px' }}>
                <label>Draw an Field on Map</label>
              </div>
              <div style={{ padding: '10px', color: "#6e7b7b" }}>
                <p>Draw your field on a map, shaping polygon with a drawing tool</p>
              </div>
              <div style={{ padding: '10px', display: 'flex' }}>
                <button onClick={() => { close() }} className="btn-save" style={{ backgroundColor: 'rgb(130 137 145)', cursor: 'pointer', marginRight: "10px" }} >CANCEL</button>
                <button onClick={() => { OnchangeDrawField(); close() }} className="btn-save" style={{ backgroundColor: '#4c99e7', cursor: 'pointer' }} >DRAW FIELD</button>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  )

}