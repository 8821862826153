import { Jimp as JimpType, JimpConstructors,ImageCallback } from '@jimp/core';
import 'jimp';

declare const Jimp: JimpType & JimpConstructors & ImageCallback;

export const validateBuffer = async (imageUrl: string) =>{
    try {
        const image = await Jimp.read(imageUrl);

        const { data, width, height } = image.bitmap;
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const idx = (y * width + x) << 2; 
            if (data[idx + 3] !== 0) { 
              return false; 
            }
          }
        } 
        return true;

      } catch (error) {
        return false; 
      }
}

export const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate() - 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
export const getFromDate = () =>{
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }