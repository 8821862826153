import './style.css';
import { FcGoogle } from "react-icons/fc";
import { GrMail } from "react-icons/gr";
import { HiEye } from "react-icons/hi";
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useState} from 'react';
import { useDispatch } from 'react-redux';
import { mapActions } from '../../actions/mapActions';

export default function Signin({ toggleSignin }) {

   const [formData, setFormData] = useState({
      email: "",
      firstName: "",
      lastName: "",
      passwordHash: "",
      phone: null,
      isAdmin: false,
      isGoogleAuth: true,
      AccessToken : ""
   });

   
   const [isDataUpdate, setIsDataUpdate]= useState(false);
   const dispatch = useDispatch();
   
   const login = useGoogleLogin({
      onSuccess: async respose => {
         try {
            const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
               headers: {
                  "Authorization": `Bearer ${respose.access_token}`
               }
            });

            if (res.status === 200) {    
            toggleSignin(res);  
               setFormData({
                  ...formData,
                  email: res.data.email,
                  firstName: res.data.given_name,
                  lastName: res.data.family_name,
                  passwordHash: null,
                  phone: null,
                  isAdmin: false,
                  isGoogleAuth: true,
                  AccessToken : "",
               });

               setIsDataUpdate(!isDataUpdate);
               window.addEventListener('triggeruserArea',()=>{              
                  dispatch(mapActions.userareaAll(localStorage.getItem('client_id')));             
               })
            }

            return  () =>{
             window.addEventListener('triggeruserArea',()=>{});
            }

         } catch (err) {
            console.log(err);
         }
      }

      
   });

   
   return (
      <div className="out-container">
         <div className="container-sign-in">
            <h2>Sign in</h2>
            <form>
               <label className="label-text">Email</label>
               <div className="inputContainer">
                  <input type="text" className="inputField" disabled />
                  <GrMail className="inputIcon" />
               </div>
               <div className="forgot-container">
                  <label className="label-text">Password</label>
                  <a href="">Forgot password ?</a>
               </div>
               <div className="inputContainer">
                  <input type="text" className="inputField" disabled />
                  <HiEye className="inputIcon" />
               </div>

               <input type="submit" value="Sign-in" />
            </form>
            <div className="container-lab">
               <label className="label-text">Or login with</label>
               <FcGoogle className="Google-icon" size={'1.4em'} onClick={() => login()} />
            </div>
            <div className="Signin-container">
               <label className="label-text">Don't have an account?
                  <a onClick={toggleSignin} href="#Sign-In">Sign up</a>
               </label>
            </div>
         </div>
      </div>
   );


}
