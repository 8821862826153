import { useState } from "react";
import MapView from "../map/map";
import Navigationmenu from '../Component/navigationMenu';
import LoginContainer from "../map/Component/LoginComponent";
import MousePositionControl from "../map/Component/MouseCoordinate";
import { Map } from "ol";
import InputGeocoder from "../map/Component/SearchBar";
import DrawingShape from "../map/Component/DrawingShape";
import LayerList from "../map/Component/LayerComponent";
import AreaOfInterest from "../map/Component/AreaOfInterest";
import Drawfarming from "../map/CropMonitor/Drawfarming";
import { Outlet } from 'react-router-dom';


function Container() {

  const [containerMapRef, setContainerMapRef] = useState<Map | null>(null);

  const handleMapRef = (mapRef: Map) => {
    setContainerMapRef(mapRef);
  };

  return (
    <>
      <MapView onMapRef={handleMapRef} />
      <Navigationmenu />
      <LoginContainer />
      <InputGeocoder map={containerMapRef} />
      <DrawingShape map={containerMapRef} />
      <LayerList map={containerMapRef} />
      <AreaOfInterest map={containerMapRef} />
      <Drawfarming map={containerMapRef} />
      <MousePositionControl map={containerMapRef} />
      <Outlet />
    </>
  );

}

export default Container;