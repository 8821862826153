import { transform } from "ol/proj";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoArrowBack } from "react-icons/io5";
import '../../../Component/sidePanel.css';
import { useEffect, useState } from "react";
import SeasonDetail from "./FieldSeasonDetail";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../../actions/mapActions";
import { FaExclamationCircle } from "react-icons/fa";
import { sowingActions } from "../../../actions/sowingActions";
import { IoMdAdd } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import * as Constants from "../../../common/constants";
import { MainForm } from "../Mainform";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fieldActions } from "../../../actions/fieldActions";

function FieldSeason({ }) {

  const [stateChange, setchange] = useState(true);
  const seasongetall = useSelector((state: any) => state.seasongetAllReducer);
  const sowingfieldid = useSelector((state: any) => state.SowingIdreducer);
  const seasonpost = useSelector((state: any) => state.seasoncreateReducer);
  const sowingAll = useSelector((state: any) => state.sowingAllReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  const [group, setGroupname] = useState('');
  const [name, setFieldname] = useState('');
  const navigate = useNavigate();
  const [fieldata, setFieldObj] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [editfield, seteditfield] = useState(false);
  const fieldData = useSelector((state: any) => state.fieldnameReducer);
  const seasonDelete = useSelector((state: any) => state.seasondeleteReducer);

  const checkIfFieldExists = (fieldid : any) =>{
    return fieldData.fieldname?.some((field: any) => field._id === fieldid);
  }

  useEffect(()=>{
    if(seasonDelete.isSuccess){
       toast.success(seasonDelete.seasondelete.message,{position : Constants.TOAST_POSITION});
    }
    seasonDelete.isSuccess = false
 },[seasonDelete.isSuccess]);

 useEffect(()=>{
   console.log(fieldData);
 },[fieldData])

 useEffect(()=>{
    if(seasonDelete.isError){
       toast.warn(seasonDelete.seasondelete.errorMessage,{position : Constants.TOAST_POSITION});
    }
    seasonDelete.isError = false
 },[seasonDelete.isError]);

 useEffect(() => {
  dispatch(fieldActions.Fieldname(localStorage.getItem('client_id')));
}, []);

  useEffect(()=>{
    if(sowingAll.isError){
      toast.warn(sowingAll.sowingDataerror.errorMessage,{position : Constants.TOAST_POSITION});
    }
    sowingAll.isError  = false
 },[sowingAll.isError]);

  useEffect(() => {
    if (location.pathname.includes('/field') && location.state.fieldId) {
      const fieldExists = checkIfFieldExists(location.state.fieldId);
      if (!fieldExists) {
        setFieldObj(null);
        setFieldname('');
        setCoordinates([]);
        setGroupname(null);
        navigate('/app/listAll');
        dispatch(mapActions.Mapstate(true));
      } else {
        const strArray = location.state.coordinate.split(',');
        const numArray = strArray.map(Number);   
        setFieldObj(location.state.fieldId);
        setFieldname(location.state.name);
        setCoordinates(numArray);
        setGroupname(location.state.groupname);
      } 
    }
  }, [location]);


  const FormatedArray = (data) => {
    const transformedCoordinates = [];
    for (let i = 0; i < data.length; i += 2) {
      const lonLat = transform([data[i], data[i + 1]], 'EPSG:3857', 'EPSG:4326');
      transformedCoordinates.push(lonLat);
    }
    const formattedArray = [].concat(...transformedCoordinates).map((value, index) => {
      if (index % 2 === 1 && index !== data.length - 2) {
        return value + ' ';
      } else {
        return value + ',';
      }
    });
    return formattedArray.join('');
  }

  const closeContainer = () => {
    seteditfield(!editfield);
  };

  useEffect(() => {
    if (fieldata) {
      dispatch(mapActions.seasonGetAll(fieldata));
      dispatch(sowingActions.SowingetAll(fieldata));
    }

  }, [fieldata]);

  useEffect(() => {

    if (sowingAll.isSuccess) {

      dispatch(sowingActions.SowingetAll(fieldata));
      dispatch(mapActions.seasonGetAll(fieldata));

      if(sowingAll.isSuccess){
        toast.success(sowingAll.sowingData.message,{position : Constants.TOAST_POSITION});
     }
     sowingAll.isSuccess = false

    }
    if (seasonpost.isSuccess) {
      dispatch(sowingActions.SowingetAll(fieldata));
      dispatch(mapActions.seasonGetAll(fieldata));
    }
  }, [sowingAll.isSuccess, seasonpost.isSuccess]);

  useEffect(()=>{
    const innerH : number =  window.innerHeight 
    document.getElementById('side-panel_id').style.height = innerH.toString();  
   },[]);


  return (
    <>
      <div className="side-panel" id='side-panel_id'>
        <div className="back-button-container" >
          <IoArrowBack style={{ cursor: 'pointer' }} onClick={() => navigate('/app/listAll')} />
          <label style={{ marginLeft: '4px' }}>Back</label>
          <hr style={{ marginTop: '20px', backgroundColor: "#0000" }} />
        </div>
        <div className="sidepanel-field-container">
          <label className="field-label">Field View</label>
          <HiQuestionMarkCircle size={'1.2em'} style={{ marginLeft: '10px' }} />
          <div className="pl">
          <div className='field-listView-container'   >
            <div className='field-list-polygon' >
              <div className="polygon-div-container" id="field-container">
                <svg className="svg-polygon" xmlns="http://www.w3.org/2000/svg" onClick={() => setchange(!stateChange)}>
                  <polygon points={coordinates && FormatedArray(coordinates)} fill='lime' stroke='black' className="custom-polygon" strokeOpacity={0.7} />
                </svg>
              </div>
              <div className='field-parameter-column'>
                <div className='label-container'>
                  <label className='label-fieldname' style={{ fontWeight: '500' }}>{name}</label>
                </div>
              </div>
            </div>
            <div className="label-season-crop">
              <label className="field-label-rotation" >Crop rotation</label>
              <FaExclamationCircle size={'0.9em'} style={{ marginLeft: '5px' }} data-tooltip-id="my-tooltip-container" data-tooltip-content={Constants.CROPROTATION} />
            </div>
            <div className="season-container">
              {
                ((sowingfieldid.sowingfieldid && seasongetall.seasonalldata)) &&

                (sowingfieldid.sowingfieldid && seasongetall.seasonalldata) && seasongetall.seasonalldata.map((item: any, index: any) => {

                  const seasonidfind = sowingfieldid.sowingfieldid.find(field => field.sowing_season_id === item._id);

                  if (seasonidfind !== undefined) {

                    return (
                      <>
                        <label key={seasonidfind} style={{ marginLeft: '10px' }}> season : {item.season_name}</label>{

                          (sowingfieldid.sowingfieldid.map((item1: any, index: any) => {
                            return (
                              <>
                                {
                                  item._id === item1.sowing_season_id && <SeasonDetail callback={closeContainer} key={seasonidfind} field={JSON.stringify(item1)} />
                                }
                              </>
                            );
                          }))
                        }
                      </>
                    );
                  }
                  else {
                    return (
                      <>
                        <label style={{ marginLeft: '10px' }}> season : {item.season_name}</label>
                        {
                          <SeasonDetail key={index} callback={closeContainer} field={''} />
                        }
                      </>
                    );
                  }
                })
              }
              {
                ((seasongetall.isSuccess && seasongetall.seasonalldata.length === 0)) && (
                  <>
                    <div className="sesaon-button-container">

                      <label>Add your seasons</label>
                      <button className="btn-cancel field-btn-add" onClick={closeContainer} >
                        <IoMdAdd style={{ marginRight: '6px', fontWeight: 'bolder' }} size={'1.1em'} />
                        Add
                      </button>
                      <div>
                        <label>No season created ? To create a new season, click on the <span >Add</span> </label>
                      </div>
                    </div>
                  </>)
              }
            </div>
          </div>
          </div>
        </div>
        
      </div>
      <Tooltip id="my-tooltip-container" className="seasonfield-container" style={{ backgroundColor: "black", color: "white", width: '200px', borderRadius: '10px', }} />
      {editfield && <MainForm close={closeContainer} fieldata={fieldata} name={name} group={group} />}
    </>
  )


}


export default FieldSeason;