import './navigationMenu.css';
import { MdDraw } from "react-icons/md";
import { FaStar } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';
import Interest from './SubmenuOption/Interest'
import AOI from './SubmenuOption/AOI';
import { GiTreeGrowth } from "react-icons/gi";
import { LiaSeedlingSolid } from "react-icons/lia";
import { useDispatch, useSelector } from 'react-redux';
import { BiScan } from "react-icons/bi";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { mapActions } from '../actions/mapActions';
import * as Constant from '../common/constants';

export default function Navigationmenu() {

    const popupRef = useRef(null);
    const diseases = useSelector((state: any) => state.cropdiseaseReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseClick);
        return () => {
            document.removeEventListener('mousedown', handleMouseClick);
        };
    }, []);

    const [showLabel, setShowLabel] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showSubMenu7, setShowSubMenu7] = useState(false);
    const [field, setField] = useState(false);
    const [identify, setIdentify] = useState(false);

    const handleSubMenuClick = (index) => {

        if (field) { setField(!field); }

        if (index === 1)
            setShowSubMenu(true);
        if (index === 7)
            setShowSubMenu7(true);

        setShowLabel(false);
    };

    const handleSubEnter = () => {
        setShowSubMenu(false);
        setShowSubMenu7(false); setShowLabel(true);
    }

    const Enterclick = (index) => {

        if (index === 1)
            setShowSubMenu(true);

        if (index === 7)
            setShowSubMenu7(true);

        setShowLabel(false);

    }

    const handleMouseClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowSubMenu(false);
            setShowSubMenu7(false);
        }
    };


    const onChangeMyfarm = () => {

        if (localStorage.getItem('AccessToken') === null) {
            toast.warn('please login to proceed', { position: Constant.TOAST_POSITION })
        } else {
            navigate('/app/listAll');
            setField(!field);
        }
    }

    const onChangeIdentify = () => {
        if (localStorage.getItem('AccessToken') !== null) {
            diseases.isSuccess = false;
            setIdentify(!identify);
            dispatch(mapActions.Mapstate(true));
            navigate('/app/crop-identify')
        } else {
            toast.warn('please login to proceed', { position: Constant.TOAST_POSITION })
        }
    }

    const onChangeGrowth = () => {
        if (localStorage.getItem('AccessToken') !== null) {
            navigate('/app/crop-growth')
        } else {
            toast.warn('please login to proceed', { position: Constant.TOAST_POSITION })
        }
    }

    return (
        <>
            <div>
                {<div className='Nav'>
                    <ul className='list-custom' ref={popupRef}>
                        <li className='nest-li' onClick={() => handleSubMenuClick(7)} onMouseEnter={handleSubEnter} >
                            <a className='nav-a-hover'><MdDraw /></a>
                            {showLabel && <div style={{ backgroundColor: '#000' }}> <span className="label">AOI</span> </div>}
                            {showSubMenu7 && (<div onMouseEnter={() => Enterclick(7)} ><AOI /></div>)}

                        </li>
                        <li className='nest-li' onClick={() => handleSubMenuClick(1)} onMouseEnter={handleSubEnter} >
                            <a className='nav-a-hover'><FaStar /></a>
                            {showLabel && <span className="label">My Area of Interest</span>}
                            {showSubMenu && (<div onMouseEnter={() => Enterclick(1)} ><Interest /></div>)}
                        </li>

                        <li className='nest-li' onClick={onChangeMyfarm} onMouseEnter={handleSubEnter}>
                            <a className='nav-a-hover' ><LiaSeedlingSolid /></a>
                            {showLabel && <span className="label">My Farm</span>}
                        </li>
                        <li className='nest-li' onClick={onChangeIdentify} onMouseEnter={handleSubEnter} >
                            <a className='nav-a-hover' ><BiScan /></a>
                            {showLabel && <span className="label">Disease Identification</span>}
                        </li>
                        <li className='nest-li' onClick={onChangeGrowth} onMouseEnter={handleSubEnter} >
                            <a className='nav-a-hover' ><GiTreeGrowth /></a>
                            {showLabel && <span className="label">Growth Identification</span>}
                        </li>
                    </ul>
                </div>

                }

            </div>
        </>
    );
}
